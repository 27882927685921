<template>
  <div>
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <b-table :data="records" hoverable>
        <b-table-column field="#" label="#" width="40" numeric v-slot="props">{{
          admins.indexOf(props.row) + 1
        }}</b-table-column>

        <b-table-column label="Name" v-slot="props">{{
          props.row.name
        }}</b-table-column>

        <b-table-column label="Email" v-slot="props">{{
          props.row.user.email
        }}</b-table-column>

        <b-table-column label="Phone" v-slot="props">{{
          props.row.phone
        }}</b-table-column>

        <b-table-column label="Auth" v-slot="props">
          <fa-icon
            icon="user-check"
            size="lg"
            class="is-small has-text-success"
            v-if="props.row.user.firebaseUserId.length > 10"
          />
          <fa-icon
            icon="user-times"
            size="lg"
            class="is-small has-text-danger"
            v-else
          />
        </b-table-column>

        <b-table-column label="Actions" width="160" v-slot="props">
          <action-buttons
            @initialize-info="initializeInfo(props.row)"
            @initialize-update="initializeUpdate(props.row)"
            @initialize-remove="initializeRemove(props.row)"
          />
        </b-table-column>
      </b-table>
    </template>
    <!-- Delete -->
    <app-modal
      id="remove-admin"
      context="remove"
      @remove="remove"
      :show-modal.sync="isModalOpen"
    />
  </div>
</template>

<script>
import gql from 'graphql-tag'
const randomstring = require('randomstring')
import { fetchUser } from '@/assets/js/app_info.js'
import { crudNotification } from '../../assets/js/notification'

export default {
  name: 'School',
  data() {
    return {
      pageTitle: 'Admins',
      user: null,
      schoolId: null,
      admins: [],
      records: [],
      id: null,
    }
  },
  watch: {
    admins(data) {
      const [admin] = data.filter((item) => item.user.id === `${this.user.id}`)
      if (admin.host) {
        this.records = data
      } else {
        this.records = data.filter((admin) => !admin.host)
      }
    },
  },
  methods: {
    initializeUpdate(admin) {
      this.$router.push(`/school/${this.schoolId}/admin/${admin.id}`)
    },
    initializeInfo(admin) {
      this.$router.push(`/school/${this.schoolId}/admin_info/${admin.id}`)
    },
    initializeRemove(admin) {
      this.id = admin.id
      this.openModal()
    },
    remove() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation DeleteAdmin($id: Int!) {
              deleteAdmin(input: { id: $id }) {
                admin {
                  id
                }
                errors
              }
            }
          `,
          variables: {
            id: parseInt(this.id),
          },
        })
        .then((response) => {
          crudNotification(
            response.data.deleteAdmin.errors,
            'Successfully deleted.'
          ).then(() => {
            this.closeModal()
            this.$apollo.queries.admins.refetch()
          })
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
  mounted() {
    this.$store.commit('setPageTitle', this.pageTitle)
    fetchUser().then((user) => {
      this.user = user
      this.schoolId = user.school_id

      this.$store.commit('setSubMenus', [
        {
          name: 'New',
          route: `/school/${this.schoolId}/admin/new`,
        },
      ])

      this.$apollo.addSmartQuery('admins', {
        query: gql`
          query AdminsQuery($id: ID!) {
            admins(id: $id) {
              id
              name
              user {
                id
                email
                googleToken
                firebaseUserId
              }
              phone
              ownerId
              host
            }
          }
        `,
        variables() {
          return { id: parseInt(this.schoolId) }
        },
        error(error) {
          this.$buefy.notification.open({
            duration: 5000,
            message: error.message,
            position: 'is-top-right',
            type: 'is-danger',
            hasIcon: true,
          })
        },
      })

      this.$apollo.queries.admins.refetch()
    })
  },
}
</script>
